<template>
    <div id="login" class="container-fluid p-0" style="min-height: 100%;">
        <div class="row d-flex justify-content-center p-0">
            <div class="col-12 col-md-4 col-lg-5 d-relative d-md-flex bg-custom px-0 p-0 justify-content-center" 
                style="display: flex; height: 100vh;" 
                :style="{ 'background-image': `url('${img_bg}')` }">
                <div class="d-flex flex-column align-items-center justify-content-center">
                <img :src="logo" />
                <!-- <img :src="title" /> -->
                <img :src="filigrama" style="height: 160px;" />
                </div>
            </div>

            <div class="col-12 col-sm-10 col-md-8 col-lg-7 
                bg-transparent bg-md-light 
                position-absolute position-md-initial 
                d-flex justify-content-center align-items-center 
                px-3 py-5 py-md-0"
                style="top: 0px; height: 100vh;">

                <form class="row px-0 px-lg-5" autocomplete="off">
                    <div class="col-12 p-3 py-5 p-sm-5 bg-white rounded-16">
                        <div class="row p-0">

                            <div class="col-12 p-0 d-flex justify-content-center align-items-center">
                                <p class="opensans-bold text-black" style="font-size: 32px;">
                                    Bienvenido
                                </p>
                            </div>

                            <!-- <div class="col-12 px-0 px-xl-5 mt-4">
                                <div class="px-0">
                                    <input v-model="email" 
                                        type="email" 
                                        class="form-control opensans-regular pl-0 pl-sm-4 py-4" 
                                        placeholder="Correo electrónico">
                                        <span class="text-danger" v-if="error_email">
                                            {{ error_email }}
                                        </span>
                                </div>
                            </div> -->
                            

                            <div class="col-12 px-0 px-xl-5 mt-4">
                                <div class="px-0" style="height: 60px">
                                    <input v-model="user_name" 
                                        type="text" 
                                        class="form-control opensans-regular pl-0 pl-sm-4 py-4 text-dark" 
                                        :autocomplete="'off'"
                                        placeholder="Nombre de usuario"/>
                                        <span class="text-danger" v-if="error_user_name">
                                            {{ error_user_name }}
                                        </span>
                                </div>
                            </div> 
                            

                            <div class="col-12 px-0 px-xl-5 mt-4 mt-sm-5">
                                <div class="row p-0">
                                    <div class="col-12 px-0 pl-sm-3 py-3 rounded" style="border: 1px solid #cfd5da;">
                                        <div class="row p-0">
                                            <div class="col-10 col-lg-11 p-0">
                                                <input type="password" autocomplete="new-password" style="display: none"/>
                                                <input v-model="password"
                                                    :type="input_type" 
                                                    :autocomplete="'jklj'"
                                                    class="opensans-regular border-0 custom_focus" 
                                                    placeholder="Contraseña" style="width: 100%;"/>
                                            </div>
                                            <div class="col-2 col-lg-1 p-0 d-flex justify-content-center align-items-center">
                                                <div class="d-flex justify-content-center align-items-center" style="max-width: 20px !important;">
                                                    <img :src="visibility" alt="" class="img-fluid" v-if="show_visibility" @click="showPassword()">
                                                    <img :src="visibility_off" alt="" class="img-fluid" v-else @click="hidePassword()">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 text-danger p-0" v-if="error_password">
                                        <span>
                                            {{ error_password }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <form class="col-12 px-0 px-xl-5" autocomplete="off">
                                <input type="text" class="d-none" autocomplete="off" />
                                <input type="password" class="d-none" autocomplete="off" />
                                <div class="row p-0">
                                    <div class="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-start align-items-center px-0 py-2 py-lg-4">
                                        <label class="switch switch-info mt-1">
                                            <input type="checkbox" 
                                                v-model="remember" 
                                                @change="rememberUser"
                                                :disabled="!user_name || !password">
                                            <span class="slider"></span>
                                        </label>
                                        <div class="d-flex justify-content-center align-items-center ml-1">
                                            <span class="opensans-regular text-black">Recuerdame</span>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end align-items-center px-0 pb-2 py-lg-4">
                                        <span class="text-danger opensans-regular text-center text-md-right">Recuperar contraseña</span>
                                    </div>
                                </div>
                            </form>
                            <div class="col-12 px-0 px-xl-5">
                                <button @click="login"
                                :disabled="pending_petition"
                                class="btn btn-primary full-width py-2"  
                                type="button" 
                                style="border-radius: 10px"><span class="opensans-semibold kn--description-sm text-white">Ingresar</span></button>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</template>

<script>
    // Icons
    import visibility from "../assets/icons/visibility";
    import visibility_off from "../assets/icons/visibility_off";

    // Images
    import img_bg from 'ASSETS/images/background.png'
    import logo from 'ASSETS/images/logo_kinver.png'
    import filigrama from 'ASSETS/images/filigrama.png'
    import title from 'ASSETS/images/title.png'

    //libs
    import AES from '../libs/AES'

    //helpers
    import { mapGetters, mapActions } from 'vuex'
    

    export default {
        name: 'Login',
        data() {
            return {
                visibility,
                visibility_off,
                img_bg,
                logo,
                filigrama,
                title,
                remember:null,
                user_name: null,
                input_type: 'password',
                error_email: '',
                error_password: '',
                error_user_name: '',
                show_visibility: true,
                checked:true,
                email: null,
                password: null,
                pending_petition: false
            }
        },
        computed: {
            ...mapGetters(['getHeaders', 'getLogin'])
        },
        methods:{
            ...mapActions(['login','createSession']),
            showPassword() {
                this.show_visibility = false
                this.input_type = 'text'
            },
            hidePassword() {
                this.show_visibility = true
                this.input_type = 'password'
            },
            redirect(name_route) {
                if(this.$route.name !== name_route) {
                    window.scrollTo(0, 0)

                    this.$router.push({
                        name: name_route
                    })
                }
            },
            isEmail (value) {
                let exp_reg = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

                if(exp_reg.test(value)) {
                    return true
                }
                else {
                    return false
                }
            },
            async login() {
                this.pending_petition = true
                this.error_email = ''
                this.error_password = ''
                this.error_user_name = ''
                let is_complete = true

                // if(_.isNull(this.email) || _.isEmpty(this.email)) {
                //     this.error_email = '*Campo obligatorio'
                //     is_complete = false
                // }

                // else if(!this.isEmail(this.email)) {
                //     this.error_email = 'No es un correo valido, intenta de nuevo'
                //     is_complete = false
                // }

                if(_.isNull(this.user_name) || _.isEmpty(this.user_name)) {
                    this.error_user_name = '*Campo obligatorio'
                    is_complete = false
                    this.pending_petition = false
                }

                if(_.isNull(this.password) || _.isEmpty(this.password)) {
                    this.error_password = '*Campo obligatorio'
                    is_complete = false
                    this.pending_petition = false
                    return
                }

                if(is_complete) {
                    const body = {
                        //"email": this.email,
                        "username": this.user_name,
                        "password": this.password
                    }

                    await this.$store.dispatch('login', body);
                    
                    this.pending_petition = false

                 
                }

            },
            rememberUser() {
                
            }
        },
        mounted() {
            // if(localStorage.getItem("Data@kinver")) {
            //     const user_data = JSON.parse(localStorage.getItem("Data@kinver"))
            //     this.user_name = user_data.locale
            //     this.password = user_data.locality
            //     this.remember = user_data.remember
            // } 
            // else {
            //     this.user_name = null
            //     this.password = null
            // }
            
        }
    }
</script>

<style lang="scss" scoped>
    @import '../assets/scss/colors.scss';

    #login {
        height: 100vh;

        .custom_focus:focus {
            outline: none;
        }

        @media (min-width: 768px) {
            .position-md-initial {
                position: initial !important;
            }
            .bg-md-light {
                background-color: $light !important;
            }
        }
        
    }
</style>